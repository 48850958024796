import React from 'react'

const AnchorLink = function({to, id, children, onClick, ...rest}) {
  const smoothScroll = (e) => {
    e.preventDefault()
    const id = to.replace('#','')
    const element = document.getElementById(id)
    if (element) element.scrollIntoView({behavior:"smooth"})
  }

  return (
    <a {...rest} onClick={smoothScroll}>
      {children}
    </a>
  )
}

export default AnchorLink
