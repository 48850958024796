/* eslint-disable jsx-a11y/anchor-has-content */

import React, { useEffect, useState, useCallback, Fragment } from 'react'
import { graphql } from 'gatsby'
// https://codebushi.com/gatsby-featured-images/
import { useClasses } from '../lib/ReactUtils'
import AnchorLink from '../components/AnchorLink'
import SEO from '../components/SEO'
import { setOpen } from '../components/Menu'
import { throttle } from 'lodash'
import ChevronRight from "../svg/ChevronRight"
import '../styles/Prose'

const Doc = function ({ data: { doc }, pageContext: { breadcrumb } }) {
  const { title, description } = doc.frontmatter
  const [current, setCurrent] = useState(doc.frontmatter.headings?.length ? doc.frontmatter.headings[0].id : null)
  const root = useClasses()

  const allowThirdLevel = doc.headings.length < 30
  const headings = doc.headings.filter(function (element) {
    return element.depth == 2 || (allowThirdLevel && element.depth == 3)
  })

  useEffect(() => {
    setCurrent(headings[0]?.id)
  }, [])

  const onScroll = useCallback(throttle(() => {
    let bottom = document.body.scrollTop + window.innerHeight
    let _current
    headings.forEach(({ id }) => {
      let section = document.getElementById(id)
      if (!section) return console.error('Cannot locate ' + id)
      if (section.offsetTop < bottom) _current = id
    })
    if (_current) {
      setCurrent(_current)
    }
  }), [])

  useEffect(() => {
    document.body.addEventListener("scroll", onScroll)
    return () => document.body.removeEventListener("scroll", onScroll)
  }, [])

  useEffect(() => {
    if (!breadcrumb) return
    let titles = []
    let current = ''
    breadcrumb.forEach(function (element) {
      if (current) current += '-'
      current += element[0]
      titles.push(current)
    })
    setOpen(titles)
  }, [breadcrumb])

  return (
    <>
      <SEO
        title={title}
        description={description}
        article
      />
      <div ref={root}>
        { /* process.env.NODE_ENV == 'development' ? <h2 className='text-4xl text-red-500 font-bold'>Order {doc.frontmatter.order}</h2> : null */}
        {breadcrumb && breadcrumb.length ?
          <nav className="bg-sky-100 text-lg md:text-xl px-5 py-3 mt-20 md:mt-8 -ml-2 rounded-md w-full text-primary-dark font-bold">
            <div>
              {breadcrumb.map((data, index) =>
                <Fragment key={index}>
                  <span>{data[0]}</span>
                  <span><ChevronRight /></span>
                </Fragment>
              )}
              <span>{title}</span>
            </div>
          </nav> : null
        }
        <div className='pb-4 mt-12 mb-4 border-b-1 border-gray-300'>
          <h1 className='text-4xl md:text-5xl font-bold text-primary-dark' >{title}</h1>
          <p className='text-xl md:text-2xl font-light leading-tight tracking-tight text-primary'>{description}</p>
        </div>
        <div className='flex flex-row md:gap-x-10'>
          <div className='flex-grow-1 w-full prose pb-25 md:pb-15 overflow-x-hidden' dangerouslySetInnerHTML={{ __html: doc.html }} />
          {headings.length > 1 ?
            <div className='hidden lg:block mt-5 w-40 xl:w-70'>
              <div className='sticky top-5 w-full flex flex-col gap-y-2 pr-2 pt-6'>
                {headings.map(({ value, depth, id }) => {
                  return (
                    <AnchorLink
                      className={
                        'basic tracking-snug leading-tight ' +
                        (depth == 3 ? 'text-xs xl:text-sm pl-2 ' : 'text-sm xl:text-base ') +
                        (current === id ? 'font-semibold tracking-tight text-orange-700' : 'font-thin tracking-snug hover:text-primary transition duration-200')
                      }
                      to={id}
                      key={id}
                    >
                      {value}
                    </AnchorLink>
                  )
                })}
              </div>
            </div> : null
          }
        </div>
      </div>
    </>
  )
}

export default Doc

export const postQuery = graphql`
  query DocBySlug($slug: String!) {
    doc: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        description
        order
        slug
      }
      headings {
        id
        value
        depth
      }
    }
  }
`
