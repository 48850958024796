import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article }) => {
  const { url } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata

  if (!title) title = defaultTitle
  if (!description) description = defaultDescription
  image = siteUrl + (image || defaultImage)

  return (
    <Helmet title={title} titleTemplate={titleTemplate} defer={false}>
      <meta name="url" property="og:url" content={url}/>
      <meta property="og:type" content={article ? "article" : "website"}/>
      <meta name="description" property="og:description" content={description}/>
      <meta name="image" property="og:image" content={image}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:title" content={title}/>
      <meta name="twitter:description" content={description}/>
      <meta name="twitter:image" content={image}/>
    </Helmet>
  )
}

export default SEO


const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
  }
`